














import { Component, Vue } from 'vue-property-decorator';
import '../../rem';

@Component({
  components: {
    InsHeader: () =>
      import(/* webpackChunkName: "main" */'@/components/fsBusiness/mobile/header/FsHeader.vue'),
    InsFooter: () =>
      import(/* webpackChunkName: "footer" */'@/components/NaBusiness/mobile/footer/NaFooter.vue'),
    InsSlideMenu: () =>
      import(/* webpackChunkName: "main" */'@/components/business/mobile/header/InsSlideMenu.vue'),
    InsMenuLayout: () =>
      import(/* webpackChunkName: "main" */'@/components/business/mobile/header/InsMenuLayout.vue'),
    InsSidebar: () => import(/* webpackChunkName: "main" */'@/components/business/mobile/header/InsSidebar.vue')
  }
})
export default class mobileIndex extends Vue {
  created () {
    Vue.prototype.vw = window.innerWidth;
  }
  mounted () {
  }
}
