
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MenuItem'
})
export default class InsMenuItem extends Vue {
  @Prop() private item!: object;

  showMemNav: boolean = false;

  handleOpen (key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose (key, keyPath) {
    console.log(key, keyPath);
  }
}
