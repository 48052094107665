










import { Component, Vue } from 'vue-property-decorator';
import { FrontE } from '@/sdk/common/SysConst';
@Component({
  components: {
    Header: () => import('@/components/fsBusiness/pc/header/FsHeader.vue'),
    Footer: () => import('@/components/NaBusiness/pc/footer/NaFooter.vue'),
    Sidebar: () => import('@/components/business/pc/header/InsSidebar.vue'),
    Menu: () => import('@/components/business/pc/header/InsElMenu.vue')
  }
})
export default class pcIndex extends Vue {
  created () {
    Vue.prototype.vw = window.innerWidth;
    this.changLange(this.$Storage.get('locale') || FrontE.defaultLang);
  }
  mounted () {
  }
  private changLange (lang) {
    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      this.$Storage.set('locale', lang);
    }).catch((error) => {
      console.log(error);
    });
  }
}
