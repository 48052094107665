











import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsCodeSelect extends Vue {
  codeId:number=1;
  changeCurrency () {
    this.$Api.member.setCurrency(this.codeId).then((result) => {
      this.$message({
        message: this.$t('changeCurSuccess') as string,
        type: 'success'
      });
      this.$store.dispatch('setCurrency', result.Currency.Id);
      this.$Storage.set('currency', result.Currency.Id);
      this.Reload();
    }).catch((error) => {
      console.log(error);
    });
  }

  LoadData () {
    this.$store.state.shopCart.then((result) => {
      // console.log(result, 'shoppingCart');
      this.codeId = result.ShopCart.Currency.Id;
    });
  }

  // get codeId () {
  //   if (this.$Storage.get('currency')) {
  //     return this.$Storage.get('currency').Id;
  //   } else {
  //     return 1;
  //   }
  // }

  // set codeId (val) {
  //   this.changeCurrency(val);
  // }
  created () {
    this.codeId = this.$store.state.currency;
  }
  mounted () {
    this.LoadData();
  }
}
