

















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsSidebar extends Vue {
    isShow: boolean = true;
    showFBMessenger: boolean = false;
    show: boolean = false;
    toTop () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // window.addEventListener('scroll', () => {
      //   let scrollY = document.documentElement.scrollTop || document.body.scrollTop;
      //   let width = (document.documentElement.offsetWidth - 1100) / 2;

      //   if (scrollY > 500) {
      //     this.isShow = true;
      //     let element = document.getElementById('sidebar') as HTMLElement;
      //     if (element) {
      //       if (width > 80) {
      //         element.style.right = (width - 120) + 'px';
      //       } else {
      //         element.style.right = '10px';
      //       }
      //     }
      //   } else {
      //     this.isShow = false;
      //   }
      // });
    }

    mounted () {
      // this.showSidebar();

      const s = document.createElement('script');
      s.type = 'text/javascript';
      let local = this.$Storage.get('locale') || this.FrontE.defaultLang;
      let lang = {
        'E': 'en_US',
        'S': 'zh_CN',
        'C': 'zh_HK'
      };
      s.src = 'https://connect.facebook.net/' + lang[local] + '/sdk.js#xfbml=1&version=v3.0';
      document.body.appendChild(s);
    }
}
