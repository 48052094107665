
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MenuItem'
})
export default class InsMenuItem extends Vue {
  @Prop() private item!: object;
  @Prop() private textColor!: string;

  showMemNav: boolean = false;

  handleOpen (key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose (key, keyPath) {
    console.log(key, keyPath);
  }

  closeSlideMenu () {
    this.$store.dispatch('isShowMenu', false);
  }
}
