

















import { Vue, Prop, Component } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
@Component
export default class InsMemberCenteral extends Vue {
    private showMenberCentral:boolean = false;
     MemberName:string='';
      isShow: boolean = false;
      show () {
        if (!this.$Storage.get('isLogin')) window.location.href = ('/account/login');
        else this.showMenberCentral = !this.showMenberCentral;
      }
      memberCentral (e) {
        let target = e.target as HTMLElement;
        if (target.className === 'ii' && target.dataset.to) {
          this.$router.push({
            path: target.dataset.to
          });
        }
      }
      // 获取会员信息
      getMemberInfo () {
        let _this = this;
        sdk.api.member.getAccount().then(
          function (data) {
            _this.MemberName = data.FullName;
          }
        );
      }
      // 关闭登录框
      closeDialog () {
        this.$emit('show');
      }

      logout () {
        this.$Api.member.logout().then((result) => {
          if (result) this.$message('Message.SucceedInOperating');
          window.location.reload();
        });
      }
      get user () {
        return this.$store.state.user;
      }
      mounted () {
        this.getMemberInfo();
      }
}
