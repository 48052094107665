import { render, staticRenderFns } from "./FsShoppingCart.vue?vue&type=template&id=ec5b7f7c&scoped=true&"
import script from "./FsShoppingCart.vue?vue&type=script&lang=ts&"
export * from "./FsShoppingCart.vue?vue&type=script&lang=ts&"
import style0 from "./FsShoppingCart.vue?vue&type=style&index=0&id=ec5b7f7c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec5b7f7c",
  null
  
)

export default component.exports