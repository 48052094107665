




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import logo from '@/components/base/mobile/InsLogo.vue';
// import DrawerUI from './InsDrawerUI.vue';
// import shopcart from '@/components/fsBusiness/mobile/header/FsShoppingCart.vue';
// import InsMemberCenteral from '@/components/fsBusiness/mobile/header/FsMemberCenteral.vue';
// import InsMenu from '@/components/fsBusiness/mobile/header/FsMenu.vue';
// import InsCodeSelect from '@/components/fsBusiness/mobile/header/FsCodeSelect.vue';
import Cookie from 'js-cookie';
@Component({
  components: {
    // logo,
    shopcart: () => import(/* webpackChunkName: "main" */'@/components/fsBusiness/mobile/header/FsShoppingCart.vue'),
    InsMemberCenteral: () => import(/* webpackChunkName: "main" */'@/components/fsBusiness/mobile/header/FsMemberCenteral.vue'),
    InsMenu: () => import(/* webpackChunkName: "main" */'@/components/fsBusiness/mobile/header/FsMenu.vue'),
    InsCodeSelect: () => import(/* webpackChunkName: "main" */'@/components/fsBusiness/mobile/header/FsCodeSelect.vue'),
    InsDrawer: () => import(/* webpackChunkName: "main" */'@/components/fsBusiness/mobile/base/FsDrawer.vue')
  }
})
export default class InsHeader extends Vue {
  direction: string = 'ttb';
  drawer: boolean = false;
  showClose: boolean = true;
  private bigLogo:boolean = true;
  private key: string = '';
  private showMenberCentral:boolean = false;
  private showChilds:boolean[] = [];
  private show:boolean = false;
  private drawerChange: boolean = false;
  private memberlogin: boolean = false;
  private count:number = 0;
  private search: boolean = false;
  private showGrandChilds = {};
  menberCentral () {
    if (!this.$Storage.get('isLogin')) window.location.href = ('/account/login');
    else this.showMenberCentral = !this.showMenberCentral;
  }
  get ShopCart () {
    return this.$store.state.shopCart;
  }
  get menberlogin () {
    return this.$Storage.get('isLogin');
  }
  logout () {
    this.$Api.member.logout().then((result) => {
      if (result) this.$message('Message.SucceedInOperating');
      window.location.reload();
    });
  }
  getMenu () {
    this.$Api.promotion.getMenu().then((result) => {
      this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
      this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
    }).catch((error) => {
      console.log(error);
    });
  }
  private changeLange (lang) {
    this.$Api.member.setUILanguage(lang).then((result) => {
      this.$i18n.locale = lang;
      // console.log(this.hhh, this.$t('test'),'test');
      localStorage.setItem('locale', lang);
      Cookie.set('uLanguage', lang);
      // this.$store.dispatch('setLang', lang);
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }
  get language () {
    return this.$store.state.lang;
  }
  // changeDrawer (flag) {
  //   this.drawer = flag;
  // }
  set language (val) {
    this.changeLange(val);
  }
  memberCentral (e) {
    let target = e.target as HTMLElement;
    if (target.className === 'ii' && target.dataset.to) {
      this.$router.push({
        path: target.dataset.to
      });
    }
  }
  showSlideMenu () {
    this.show = !this.show;
    // this.$store.dispatch('isShowMenu', !this.$store.state.isShowMenu);
  }
  searchKeyChange () {
    this.$store.dispatch('setSearchKey', this.key);
    // if (this.$route.name !== 'productSearch') {
    this.$router.push({
      path: '/product/search',
      name: 'productSearch',
      params: {
        key: this.key
      }
    });
    this.show = !this.show;
    // }
  }
  created () {
    // this.$store.dispatch('setShopCart', this.$Api.shoppingCart.getShoppingCart());
    if (this.$route.name !== 'home') { this.bigLogo = false; }
    window.onscroll = () => {
      if (this.$route.name !== 'home') return;
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      if (scrollTop >= 100) this.bigLogo = false;
      else this.bigLogo = true;
    };
    window.addEventListener('click', (e) => {
      let target = e.target as HTMLElement;
      if (target.className !== 'showMenberCentral') this.showMenberCentral = false;
    });
  }
  mounted () {
    this.getMenu();
  }
  @Watch('$route', { deep: true })
  onRouteChange () {
    this.showMenberCentral = false;
    this.search = false;
    if (this.$route.name !== 'home') { this.bigLogo = false; } else { this.bigLogo = true; }
  }
  // @Watch('key')
  // searchKeyChange () {
  //   this.Shake(() => {
  //     this.$store.dispatch('setSearchKey', this.key);
  //     if (this.$route.name !== 'productSearch') {
  //       this.$router.push({
  //         path: '/product/search',
  //         name: 'productSearch',
  //         params: {
  //           key: this.key
  //         }
  //       });
  //     }
  //   }, 1000);
  //   this.show = !this.show;
  // }
  @Watch('ShopCart')
  onShopCartChange () {
    this.ShopCart.then((result) => {
      this.count = result.ShopCart.Qty;
    });
  }
   @Watch('menberlogin')
  onmenberlogin () {
    if (!this.$Storage.get('isLogin')) this.memberlogin = false;
    else this.memberlogin = true;
  }
}
