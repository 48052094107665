






























import { Vue, Prop, Component } from 'vue-property-decorator';
import Collapse from '@/components/service/Collapse.vue';
@Component({ components: { Collapse } })
export default class InsMenu extends Vue {
    private showGrandChilds = {};
    private showChilds = {};
    @Prop({ default: false }) show!: boolean;
    get header () {
      this.$store.state.headerMenus.forEach((element, index) => {
        if (element.Childs) this.$set(this.showChilds, index, false);
        else this.$set(this.showChilds, index, false);
        if (element.Childs) {
          element.Childs.forEach(e => {
            if (e.Childs) this.$set(this.showGrandChilds, e.Value.Id, true);
            else this.$set(this.showGrandChilds, e.Value.Id, false);
          });
        }
      });
      return this.$store.state.headerMenus;
    }
    closeMenuChids (index) {
      this.showChilds[index] = !this.showChilds[index];
      this.$emit('drawerChange');
    // this.show = !this.show;
    }
    private To (item) {
      if (item.Type === 0) this.$router.push(item.Url);
      else if (item.Type === 2) this.$router.push('/cms/content/' + item.Value.Id);
      else if (item.Type === 4 && item.Value.Id) this.$router.push('/product/cat/' + item.Value.Id);
      this.$emit('show');
    }
    closeMenuGrandChids (item) {
      this.showGrandChilds[item.Value.Id] = !this.showGrandChilds[item.Value.Id];
      this.$emit('drawerChange');
    // this.show = !this.show;
    }
}
