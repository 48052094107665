












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Scrollbar from 'element-ui/lib/scrollbar';
@Component({ components: { Scrollbar } })
export default class InsSlideMenu extends Vue {
  @Prop({ default: 'left' }) private direction !: string;

  get showMenu () {
    return this.$store.state.isShowMenu;
  }

  handleClickOutside () {
    this.$store.dispatch('isShowMenu', false);
  }

  @Watch('showMenu', { deep: true })
  onShowMenu (val) {
    if (val) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
}
